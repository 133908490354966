
export const FANTOM = 250;

export const addresses = {

  [FANTOM]: {
    EQUAL: "0x3Fd3A0c85B70754eFc07aC9Ac0cbBDCe664865A6",
    ES_EQUAL: "0x5A420F4364B8C046d7134d5512a65151D4Db1413",
    EQUITY: "0x8B3E5eFaA3eA8e875112c6464218c28Db8F0b4d9",
    BN_EQUAL: "0x24078e05EC0056539551d0e111c3660825919a5e",
    STAKED_EQUAL_TRACKER: "0x70f752c2EC0765c96996CA5fAA2cb8A5DD0E9057", 
    STAKED_EQUITY_TRACKER: "0x1f2A3023339995d9e04971F5b01941351D9f220f", 
    BONUS_EQUAL_TRACKER: "0x1128d6237124BdD54675F10393FC76DA071a2306",
    FEE_EQUAL_TRACKER: "0xa5bDF77FBa489512903c7683DC605B61a1235692", 
    FEE_EQUITY_TRACKER: "0xBb061C7Dc70B852335f6F61764adEC930DC76Ebe", 
    FTM: "0x21be370D5312f44cB42ce377BC9b8a0cEF1A4C83",
    // BTC: "0x321162Cd933E2Be498Cd2267a90534A804051b11", // 8 decimals wrapped btc
    ETH: "0x74b23882a30290451A17c44f4F05243b6b58C76d", // 18 decimals wrapped eth
    RewardReader: "0x3e8B14B5534333A2B83a31d778ec3bCd9dc946f4", 
    EquityManager: "0x82f59f4a17f20Da83D5021e1D194323c5E375aC0", 
    Router: "0xd311Fd89e8403c2E90593457543E99cECc70D511", 
    OrderBook: "0xA83F31aF44e812d2EdF0536516e7D274cd7301B8", 
    PositionManager: "0xCD9aFEab223ed4aCcE3E12E010932982E8368Fbc", 
    FastPriceFeed: "0x79E24398D6bBE543Cfc0400C8Cb21ac974D5D760", 
    PositionRouter: "0xf2BfB9cA6e21b30034b9d56Cb4735d2c180cC7e1", 
    PositionExecutorUpKeep: ""
  },
};

export function getAddress(chainId, key) {
  if (!chainId in addresses) {
    throw new Error(`Unknown chain ${chainId}`);
  }
  if (!(key in addresses[chainId])) {
    throw new Error(`Unknown address key ${key}`);
  }
  return addresses[chainId][key];
}
